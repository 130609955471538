import { cleanUrlName } from "~/utils/strings.utils";

export const setProductsPurchaseOrigin = (
  products,
  purchaseOriginName,
  purchaseOriginLink = undefined,
) => {
  const purchaseOrigin = `GA-${purchaseOriginLink ?? cleanUrlName(useRoute().name)}-${purchaseOriginName}`;

  products.forEach((product) => {
    product.setExtra("purchaseOrigin", purchaseOrigin);
  });
};
